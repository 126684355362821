import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import ArrowRightIcon from "../../../public/assets/icons/ArrowRightIcon";
import LargeLogo from "../../../public/assets/icons/LargeLogo";
import GoogleAd from "../../features/common/components/GoogleAd";
import { thitotDomainUrl } from "../constants/env";

const CountdownRedirectPage = () => {
  const [countdown, setCountdown] = useState<number>(20);
  const isMobile = useMediaQuery("(max-width: 767px)");

  const redirectPage = () => {
    window.location.replace(thitotDomainUrl as string);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown((prev) => {
        if (prev > 0) return prev - 1;
        return prev;
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (countdown === 0) redirectPage();
  }, [countdown]);

  return (
    <Box className="flex flex-col items-center">
      <Box className="w-full max-w-[1200px] flex flex-col items-center gap-[88px] p-[80px]">
        <Box className="flex items-center sm:gap-[24px] md:gap-[80px]">
          <Box className="flex items-center gap-2">
            <LargeLogo size={isMobile ? 48 : 64} />
            chinhxac.vn
          </Box>
          <Box>
            <ArrowRightIcon variant={isMobile ? "medium" : "large"} />
          </Box>
          <Box className="flex items-center gap-2">
            <LargeLogo size={isMobile ? 48 : 64} />
            thitot.vn
          </Box>
        </Box>
        <hr className="border-top border-gray1 w-full" />
        <Box className="flex flex-col items-center gap-[72px]">
          <Box className="flex flex-col items-center gap-10">
            <Box className="flex flex-col items-center">
              <Typography className="text-[19px] font-medium">chinhxac.vn đã chuyển sang thitot.vn</Typography>
              <Typography className="text-[19px] font-medium">
                Bạn được tự động chuyển hướng đến trang thitot.vn
              </Typography>
            </Box>
            <Typography className="uppercase text-green text-[19px] font-medium">
              Tự động chuyển hướng đến trang sau {countdown} giây
            </Typography>
          </Box>
          <Button
            className="bg-green-gradient normal-case text-white rounded-[100px] w-[343px] h-[44px] text-[13px] font-medium"
            onClick={redirectPage}
          >
            Chuyển đến ngay
          </Button>
        </Box>
      </Box>
      <Box>
        <GoogleAd
          style={{
            display: "block",
            height: 293,
            width: 1440
          }}
          slot={process.env.NEXT_PUBLIC_GOOGLE_AD_SLOT_ARTICLE}
          adTypeTracking="ads_native_ads_view"
        />
      </Box>
    </Box>
  );
};

export default CountdownRedirectPage;
