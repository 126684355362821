import React from "react";

type TArrowRightIconProps = {
  variant: "large" | "medium";
};

const ArrowRightIcon = ({ variant = "large" }: TArrowRightIconProps) => {
  const size: { width?: number; height?: number } = {};

  if (variant === "large") {
    size.width = 63;
    size.height = 46;
  } else if (variant === "medium") {
    size.width = 46;
    size.height = 32;
  }

  return (
    <svg width={size.width} height={size.height} viewBox="0 0 63 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.5 20C1.84315 20 0.5 21.3431 0.5 23C0.5 24.6569 1.84315 26 3.5 26V20ZM61.6213 25.1213C62.7929 23.9497 62.7929 22.0503 61.6213 20.8787L42.5294 1.7868C41.3579 0.615223 39.4584 0.615223 38.2868 1.7868C37.1152 2.95837 37.1152 4.85786 38.2868 6.02944L55.2574 23L38.2868 39.9706C37.1152 41.1421 37.1152 43.0416 38.2868 44.2132C39.4584 45.3848 41.3579 45.3848 42.5294 44.2132L61.6213 25.1213ZM3.5 26H59.5V20H3.5V26Z"
        fill="#666666"
      />
    </svg>
  );
};

export default ArrowRightIcon;
